import { useState } from 'react';
import axios from 'axios';

const useUpdateUserRegistrationStatus = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const updateUserRegistrationStatus = async (user_id, registration_allowed) => {
        setLoading(true);
        setError(null);
        setSuccess(false);
        try {
            await axios.put(
                `https://konsierz-backend.plutus-fin.ru/api/users/goreg`,
                {},
                { params: { user_id, registration_allowed } }
            );
            setSuccess(true);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return {  success, updateUserRegistrationStatus };
};

export default useUpdateUserRegistrationStatus;
