import { useState, useEffect } from 'react';

const useGetUsers = () => {
    const [users, setusers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0);

    const reloadData = () => {
        setRefreshKey(prevKey => prevKey + 1);
    };

    useEffect(() => {
        const fetchusers = async () => {
            setLoading(true);
            try {
                const response = await fetch(`https://konsierz-backend.plutus-fin.ru/api/users/geta`);
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const result = await response.json();
                setusers(result.user);
            } catch (error) {
                console.error('Error fetching users:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchusers();
    }, [refreshKey]);

    return { users, loading, error, reloadData };
};

export default useGetUsers;
