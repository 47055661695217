import { useState, useEffect } from 'react';
import axios from 'axios';

const useUserLength = () => {
  const [userLength, setUserLength] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserLength = async () => {
      try {
        const response = await axios.get('https://konsierz-backend.plutus-fin.ru/api/users/getLenght');
        setUserLength(response.data.length);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserLength();
  }, []);

  return { userLength, loading, error };
};

export default useUserLength;
