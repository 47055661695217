// Sidebar.js
import React from 'react';

const Sidebar = ({ isOpen, pages, onItemClick }) => {
    return (
        <div className={`sidebar${isOpen ? '' : ' close'}`}>
            <a href="#" className="logo">
                <i className='bx bx-code-alt'></i>
                <div className="logo-name"><span>Servantes</span></div>
            </a>
            <ul className="side-menu">
                {pages.map((page) => (
                    <li key={page.name} className={page.active ? 'active' : ''}>
                        <a href="#" onClick={() => onItemClick(page.name)}>
                            <i className={`bx ${page.icon}`}></i>
                            {page.name}
                        </a>
                    </li>
                ))}
            </ul>
            <ul className="side-menu">
                <li>
                    {/* <a href="#" className="logout">
                        <i className='bx bx-log-out-circle'></i>
                        Logout
                    </a> */}
                </li>
            </ul>
        </div>
    );
};

export default Sidebar;
