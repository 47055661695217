// ./components/Popup.jsx
import React, { useState } from 'react';
import useAddProducts from '../hooks/useAddProducts';



const Popup = ({ onClose, name, category, color, imagePath,  isEditing, isCopy }) => {
    const [formData, setFormData] = useState({
        name: name || '',
        category: category || '',
        image: null,
        color: color || ''
    });
    
    
    const [isImageFieldDisabled, setIsImageFieldDisabled] = useState(isEditing);

    const { addProduct, loading, error } = useAddProducts();

    const handleChange = (e) => {
        if (e.target.type === 'file') {
            // Сохраняем файл в стейт
            setFormData({
                ...formData,
                image: e.target.files[0] // Присваиваем объект File
            });
        } else {
            // Обычное обновление текстовых полей
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            });
        }
    };

    const handleAddProduct = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('name', formData.name);
            formDataToSend.append('category', formData.category);
            formDataToSend.append('image', formData.image); // Добавляем файл в FormData
            formDataToSend.append('color', formData.color);

            const result = await addProduct(formDataToSend);
            console.log('Продукт успешно добавлен:', result);
            onClose(); // Закрываем попап после успешного добавления
        } catch (error) {
            console.error('Ошибка при добавлении продукта:', error);
        }
    };
    const handleCheckboxChange = (e) => {
        setIsImageFieldDisabled(!e.target.checked);
    };
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-8 rounded-lg w-full max-w-lg max-h-[60%] overflow-y-auto top-20">
                <h2 className="text-2xl mb-4">
                    {isEditing ? `Изменить категорию` : isCopy ? 'Копия категории' : 'Добавить категорию'}
                    </h2>

                
                <form>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Название категории</label>
                        <input 
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                        type="text" 
                        placeholder="Название категории" 
                        name="name"
                        value={formData.name}
                        onChange={handleChange}/>
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Линейка категории</label>
                        <select 
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                            name="category"
                            value={formData.category}
                            onChange={handleChange}
                        >
                            <option value="" disabled>Выберите пол</option>
                            <option value="man">Мужской</option>
                            <option value="girl">Женский</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Цвет текста</label>
                        <select 
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                            name="color"
                            value={formData.color}
                            onChange={handleChange}
                        >
                            <option value="" disabled>Выберете цвет текста:</option>
                            <option value="black">Черный</option>
                            <option value="white">Белый</option>
                        </select>
                    </div>

                {(isEditing || isCopy)&& (
                <div className="mt-2">
                    <label className="inline-flex items-center">
                        <input
                            type="checkbox"
                            className="form-checkbox h-5 w-5 text-blue-600 transition duration-150 ease-in-out"
                            onChange={handleCheckboxChange}
                            defaultChecked={!isImageFieldDisabled} // Отмечаем галочку, если поле активно
                        />
                        <span className="ml-2 text-gray-700">Я хочу поменять картинку</span>
                    </label>
                </div>
            )}
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Поле для вставки картинки</label>
                        <input 
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                            type="file"
                            name="image"
                            onChange={handleChange}
                            disabled={isImageFieldDisabled}
                        />
                    </div>
                    <div className="flex items-center justify-between">
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={onClose}
                        >
                            Закрыть
                        </button>
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={handleAddProduct}
                        >
                            {isEditing ? 'Изменить' : 'Добавить'}
                        </button>
                    </div>
                </form>
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </div>
        </div>
    );
};

export default Popup;
