import { useState } from 'react';

const useAddProducts = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const addProduct = async (formData) => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch('https://konsierz-backend.plutus-fin.ru/api/products/add', {
                method: 'POST',
                body: formData
            });

            if (!response.ok) {
                throw new Error('Ошибка при добавлении продукта');
            }

            const data = await response.json();
            setLoading(false);
            return data;
        } catch (error) {
            setLoading(false);
            setError(error.message || 'Произошла ошибка');
            throw error;
        }
    };

    return { addProduct, loading, error };
};

export default useAddProducts;
