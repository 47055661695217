import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useProductDel from '../hooks/useProductDel';
import Popup from './Popup';


const PopupProduct = ({ productId, onClose }) => {
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [newImage, setNewImage] = useState(null);
    const { deleteProduct } = useProductDel();
    const [currentPage, setCurrentPage] = useState('openTable');

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const response = await fetch(`https://konsierz-backend.plutus-fin.ru/api/products/get?id=${productId}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch product details');
                }
                const productData = await response.json();
                setProduct(productData.products[0]); // Assuming products is an array with one product
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        if (productId) {
            fetchProductDetails();
        }
    }, [productId]);

    if (loading) {
        return <p>Loading...</p>; // Отображаем заглушку во время загрузки
    }

    if (error) {
        return <p>Error fetching product details: {error.message}</p>; // Отображаем ошибку, если она произошла
    }

    if (currentPage === 'editProduct') {
        return <Popup 
        onClose={onClose}
        productId={productId}
        size_p={product.size !== null ? product.size : ''} 
        color_p={product.color !== null ? product.color : ''} 
        barnd_p={product.brand !== null ? product.brand : ''} 
        name_p={product.name !== null ? product.name : ''} 
        cost_p={product.cost !== null ? product.cost : ''} 
        status_p={product.status !== null ? product.status : ''} 
        kit_p={product.kit !== null ? product.kit : ''} 
        tag_p={product.tag !== null ? product.tag : ''} 
        product_user_p={product.product_user !== null ? product.product_user : ''} 
        out_cost_p={product.out_cost !== null ? product.out_cost : ''}
        image_path_p = {product.image_path !== null ? product.image_path : ''}
        isEditing = {true}
    />
    }
    if (currentPage === 'CopyProduct'){
        return <Popup 
        onClose={onClose}  
        size_p={product.size !== null ? product.size : ''} 
        color_p={product.color !== null ? product.color : ''} 
        barnd_p={product.brand !== null ? product.brand : ''} 
        name_p={product.name !== null ? product.name : ''} 
        cost_p={product.cost !== null ? product.cost : ''} 
        status_p={product.status !== null ? product.status : ''} 
        kit_p={product.kit !== null ? product.kit : ''} 
        tag_p={product.tag !== null ? product.tag : ''} 
        product_user_p={product.product_user !== null ? product.product_user : ''} 
        out_cost_p={product.out_cost !== null ? product.out_cost : ''}
        image_path_p = {product.image_path !== null ? product.image_path : ''}
        isCopy = {true}
    />
    }
    const handleDelete = async () => {
        await deleteProduct(productId);
        onClose();
    };

    const handleEditClick = async() => {
        
        setCurrentPage('editProduct');
        await deleteProduct(productId);

    };

    const handleSaveClick = () => {
        // Здесь можно добавить логику для сохранения изменений
        setIsEditing(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProduct((prevProduct) => ({
            ...prevProduct,
            [name]: value,
        }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setNewImage(URL.createObjectURL(file));
        }
    };

    const handleCopy =() =>{
        
        setCurrentPage('CopyProduct');
    }
    // Если данные получены успешно, отображаем информацию о товаре
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-8 rounded-lg w-full max-w-lg max-h-[80%] overflow-y-auto top-50">
                <div className="relative mb-4">
                    <img
                        src={`https://konsierz-backend.plutus-fin.ru/${product.image_path}`}
                        alt={product.name}
                        className="w-full h-64 object-cover rounded-t-lg"
                    />
                    <div className="absolute top-0 right-0 m-0">
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-1 rounded focus:outline-none focus:shadow-outline"
                            onClick={onClose}
                        >
                            <i className='bx bx-x'></i>
                        </button>
                    </div>
                </div>
                <div className="p-4">
                    {isEditing ? (
                        <input
                            type="text"
                            name="name"
                            value={product.name}
                            onChange={handleInputChange}
                            className="block w-full p-2 mb-4 border rounded"
                        />
                    ) : (
                        <h2 className="text-2xl mb-4">{product.name}</h2>
                    )}
                    <div className="mb-4">
                        <p className="block text-gray-700 text-sm mb-2">
                            <strong>Брэнд:</strong>
                            {isEditing ? (
                                <input
                                    type="text"
                                    name="brand"
                                    value={product.brand}
                                    onChange={handleInputChange}
                                    className="block w-full p-2 border rounded"
                                />
                            ) : (
                                product.brand
                            )}
                        </p>
                    </div>
                    <div className="mb-4">
                        <p className="block text-gray-700 text-sm mb-2">
                            <strong>Стоимость: </strong>
                            {isEditing ? (
                                <input
                                    type="text"
                                    name="cost"
                                    value={product.cost}
                                    onChange={handleInputChange}
                                    className="block w-full p-2 border rounded"
                                />
                            ) : (
                                product.cost
                            )}
                        </p>
                    </div>
                    <div className="mb-4">
                        <p className="block text-gray-700 text-sm mb-2">
                            <strong>Зачеркнутая цена: </strong>
                            {isEditing ? (
                                <input
                                    type="text"
                                    name="out_cost"
                                    value={product.out_cost}
                                    onChange={handleInputChange}
                                    className="block w-full p-2 border rounded"
                                />
                            ) : (
                                product.out_cost
                            )}
                        </p>
                    </div>
                    <div className="mb-4">
                        <p className="block text-gray-700 text-sm mb-2">
                            <strong>Статус товара: </strong>
                            {isEditing ? (
                                <input
                                    type="text"
                                    name="status"
                                    value={product.status}
                                    onChange={handleInputChange}
                                    className="block w-full p-2 border rounded"
                                />
                            ) : (
                                product.status
                            )}
                        </p>
                    </div>

                    <div className="mb-4">
                        <p className="block text-gray-700 text-sm mb-2">
                            <strong>Размер: </strong>
                            {isEditing ? (
                                <input
                                    type="text"
                                    name="size"
                                    value={product.size}
                                    onChange={handleInputChange}
                                    className="block w-full p-2 border rounded"
                                />
                            ) : (
                                product.size
                            )}
                        </p>
                    </div>
                    
                    <div className="mb-4">
                        <p className="block text-gray-700 text-sm mb-2">
                            <strong>Комплект товара: </strong>
                            {isEditing ? (
                                <input
                                    type="text"
                                    name="kit"
                                    value={product.kit}
                                    onChange={handleInputChange}
                                    className="block w-full p-2 border rounded"
                                />
                            ) : (
                                product.kit
                            )}
                        </p>
                    </div>

                    <div className="mb-4">
                        <p className="block text-gray-700 text-sm mb-2">
                            <strong>Цвет: </strong>
                            {isEditing ? (
                                <input
                                    type="text"
                                    name="color"
                                    value={product.color}
                                    onChange={handleInputChange}
                                    className="block w-full p-2 border rounded"
                                />
                            ) : (
                                product.color
                            )}
                        </p>
                    </div>

                    <div className="mb-4">
                        <p className="block text-gray-700 text-sm mb-2">
                            <strong>ID пользователя: </strong>
                            {isEditing ? (
                                <input
                                    type="text"
                                    name="product_user"
                                    value={product.product_user}
                                    onChange={handleInputChange}
                                    className="block w-full p-2 border rounded"
                                />
                            ) : (
                                product.product_user
                            )}
                        </p>
                    </div>



                    {/* Повторите вышеуказанный шаблон для других полей */}
                    <div className="flex items-center justify-between">
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={handleDelete}
                        >
                            Удалить
                        </button>
                        {isEditing ? (
                            <button
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                type="button"
                                onClick={handleSaveClick}
                            >
                                Сохранить
                            </button>
                        ) : (
                            <button
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                type="button"
                                onClick={handleEditClick}
                            >
                                Изменить
                            </button>
                        )}
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={handleCopy}
                        >
                            Создать копию
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

PopupProduct.propTypes = {
    productId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default PopupProduct;
